import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { getAssetsThunk, getFeatureFlagsThunk, setCurrentAsset } from "../../redux/actions/misc-actions";
import { getAdSpacesThunk, setAdSpaceConsultation } from "../../redux/actions/adspaces-actions";
import FirstContactAdSpaceComponent from "../../components/first-contact/ad-space";
import { sendFirstContactWithoutSpaceThunk } from "../../redux/actions/first-contact-actions";
import SigninModal from "../../components/signin-modal";
import { sendBookingRequestThunk } from "../../redux/actions/consultation-actions";
import { CONSULT_STATUS } from "../../redux/reducers";
import { useNavigate } from "../../hooks/navigation";

const FirstContactContainer = () => {
  const { navigate } = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const [booking, setBooking] = useState({ asset: {} });

  const assets = useSelector((state) => state.misc.assets);
  const bookingId = useSelector((state) => state.firstContact.bookingId);
  const status = useSelector((state) => state.firstContact.status);
  const user = useSelector((state) => state.user.data);
  const userStatus = useSelector((state) => state.user.status);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const adSpaces = useSelector((state) => state.adspaces.adSpaces);
  const bookingData = useSelector((state) => state.consultation.data);
  const [requestStatus, setRequestStatus] = useState("initial");

  // eslint-disable-next-line camelcase
  const { start, end, adspaces } = queryString.parse(location.search);

  useEffect(() => {
    if (bookingData.state === CONSULT_STATUS.REQUESTED) {
      navigate(`/tenant/inprogress/chat/${booking.bookingId}`);
    }
  }, [bookingData]);

  useEffect(() => {
    if (status === "success") {
      setRequestStatus("loading");
      dispatch(sendBookingRequestThunk({ bookingId }));
    }
  }, [status, bookingId, dispatch]);

  const handleSubmit = (message) => {
    dispatch(
      sendFirstContactWithoutSpaceThunk({
        message,
        assetId: params.assetId,
        start,
        end,
      }),
    );
  };

  useEffect(() => {
    dispatch(getAssetsThunk());
    dispatch(getFeatureFlagsThunk());
  }, []);

  useEffect(() => {
    if (assets.length) {
      const asset = assets.find((e) => e.id === Number(params.assetId));
      if (asset) {
        setBooking({ startDate: start, endDate: end, asset });
        dispatch(getAdSpacesThunk({ asset: asset.id }));
        dispatch(setCurrentAsset(asset));
      }
    }
  }, [assets]);

  useEffect(() => {
    const adSpacesIds = adspaces?.length ? adspaces.split(",").map((id) => Number(id)) : [];
    setBooking((b) => ({
      ...b,
      adSpaces: adSpaces.filter((ad) => adSpacesIds.includes(ad.id)),
    }));
    dispatch(setAdSpaceConsultation(adSpaces.filter((ad) => adSpacesIds.includes(ad.id))));
  }, [adSpaces]);

  return (
    <>
      <FirstContactAdSpaceComponent
        booking={{ bookingId, ...booking }}
        user={user}
        onSubmit={handleSubmit}
        status={status}
        requestStatus={requestStatus}
      />
      <SigninModal isOpen={!isAuthenticated && userStatus !== "loading"} />
    </>
  );
};

export default FirstContactContainer;
